import axiosInstance from '../interceptor/interceptor';
import proxyInstance from '../interceptor/proxyInterceptor'

const createOrg = async (data) =>
{
    // return await axiosInstance.post( "/orgs",data)
    return await proxyInstance.post("/c/createCompany", {company : {name : data}})
}

const getAllOrgs = async () => 
{
    // return await axiosInstance.post("/orgs/getsomeorgs",{userIds:data});
    return await proxyInstance.get("/c/getCompanies?itemsPerPage=100").then(data => data.data);
}

const switchOrg = async (data) => {
    return await proxyInstance.post(`/c/switchCompany`, data)
}

const switchOrgBE = async (data) => {
        return await axiosInstance.post('/orgs/switchOrg', data);
}

const getDbsByOrg = async (orgId) => {
    return await axiosInstance.get(`/dbs/${orgId}/dbs`);
}

const getOrgById = async (id) =>
{
    // return await axiosInstance.get(`/orgs/${id}`);
    return await proxyInstance.get(`/{featureId}/getCompanies?id=${id}`)
}

const addUserInOrg = async (data) =>
{
    // const okk=  await axiosInstance.patch( `/orgs/${id}/handleUser?operation=add`,data);
    // return okk;
    return await proxyInstance.post('/c/addUser', data);
}

const addUserInOrgBE = async (data, orgId) => {
    return axiosInstance.patch(`/orgs/${orgId}/handleUser?operation=add`, data)
}

const updateUserType = async (id, data) =>
{
    return  await axiosInstance.patch( `/orgs/${id}/handleUser?operation=update`,data)  
}


const updateOrg = async (data) =>
{
    // return await axiosInstance.patch( `/orgs/${id}`,data)
    return await proxyInstance.put('/c/updateCompany', {company : data})
}

const removeUserInOrg = async (orgId, userId) =>
{
    return await axiosInstance.patch( `/orgs/${orgId}/handleUser?operation=remove`,{ userId })
}    

const deleteOrg = async (id)=>
{
    return await axiosInstance.delete( `/orgs/${id}/`)
}




export {
    createOrg,
    getAllOrgs,
    getOrgById,
    addUserInOrg,
    updateOrg,
    removeUserInOrg,
    deleteOrg,
    updateUserType,
    switchOrg, 
    switchOrgBE,
    getDbsByOrg,
    addUserInOrgBE,
}